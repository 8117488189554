import Swiper from 'swiper';
import { Navigation, Scrollbar, FreeMode, Pagination, Autoplay } from 'swiper/modules';

const BannerSwiper = new Swiper(".banner-swiper", {
    modules: [Pagination, Autoplay],
    slidesPerView: 1,
    spaceBetween: 30,
    cssMode: true,
    loop: true,
    autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
    pagination: {
      el: ".banner-pagination",
      clickable: true,
    },
    mousewheel: true,
    keyboard: true,
  }); 
  
  const WorksSwiper = new Swiper(".works-swiper", {
    modules: [Navigation, FreeMode],
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: false,
    /* freeMode: true, */
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.works-swiper-button-next',
      prevEl: '.works-swiper-button-prev',
    },
  }); 