document.addEventListener('DOMContentLoaded', function () {
    const items = document.querySelectorAll('.card-service');
    const showMoreBtn = document.querySelector('.show-more');
    const buttons = document.querySelectorAll('.category-button');
    let itemsPerPage = 4;
    let currentPage = 1;
    let currentCategory = 'all';

    if (items.length === 0) {
        if (showMoreBtn) {
            showMoreBtn.style.display = 'none';
            return;
        }
    }
    
    const categoryBtn = document.querySelector('.category-button[data-category-id="all"]');
    if(categoryBtn){
        categoryBtn.classList.add('active');
    }

    function showItems() {
        let visibleItemsCount = 0;
        let categoryItemsCount = 0;

        items.forEach((item, index) => {
            const serviceCategory = item.getAttribute('data-category');

            if (currentCategory === 'all' || serviceCategory === currentCategory) {
                categoryItemsCount++;

                if (visibleItemsCount < currentPage * itemsPerPage) {
                    item.style.display = 'flex';
                    visibleItemsCount++;
                } else {
                    item.style.display = 'none';
                }
            } else {
                item.style.display = 'none';
            }
        });

        if (categoryItemsCount === 0 || visibleItemsCount >= categoryItemsCount) {
            showMoreBtn.style.display = 'none';
        } else {
            showMoreBtn.style.display = 'flex';
        }
    }

    function updateActiveClass(clickedButton) {
        buttons.forEach(button => {
            button.classList.remove('active');
        });
        clickedButton.classList.add('active');
    }

    buttons.forEach(button => {
        button.addEventListener('click', function () {
            currentCategory = this.getAttribute('data-category-id');
            currentPage = 1;
            updateActiveClass(this);
            showItems();
        });
    });

    if(showMoreBtn){
        showMoreBtn.addEventListener('click', function () {
            currentPage++;
            showItems();
        });
        showItems();
    }


});


document.addEventListener('DOMContentLoaded', () => {
    const galleryBlocks = document.querySelectorAll('.gallery-first__block');

    galleryBlocks.forEach(galleryBlock => {
        const galleryList = galleryBlock.querySelector('.gallery-first__list');
        const images = galleryList.querySelectorAll('.gallery-show-more');
        const showMoreButton = galleryBlock.querySelector('.gallery-first__btn');
        const galleryItems = galleryList.querySelectorAll('.gallery-first__image');

        // Функция для обновления эффекта blur
        const updateBlurEffect = (lastVisible, btn) => {
            const lastImage = lastVisible?.querySelector('.gallery-show-more');
            if (lastImage) {
                if (btn && btn.style.display === 'block') {
                    lastImage.style.filter = 'blur(5px)';
                } else {
                    lastImage.style.filter = 'none';
                }
            }
        };

        if (showMoreButton) {
            if (images.length > 9) {
                images.forEach((img, index) => {
                    if (index >= 9) img.style.display = 'none';
                });

                showMoreButton.style.display = 'block';

                showMoreButton.addEventListener('click', () => {
                    const hiddenImages = Array.from(images).filter(img => img.style.display === 'none');
                    hiddenImages.slice(0, 3).forEach(img => {
                        img.style.display = 'block';
                        img.style.filter = 'none';
                    });

                    if (hiddenImages.length <= 3) {
                        showMoreButton.style.display = 'none';
                    }
                });
            }
        }

        if (galleryItems.length > 9) {
            galleryItems.forEach((item, index) => {
                if (index >= 9) item.classList.add('hidden');
            });

            const updateShowMoreButton = () => {
                galleryItems.forEach(item => item.classList.remove('active'));

                const visibleItems = Array.from(galleryItems).filter(item => !item.classList.contains('hidden'));
                const lastVisible = visibleItems[visibleItems.length - 1];

                if (lastVisible) {
                    lastVisible.classList.add('active');

                    const hiddenItems = Array.from(galleryItems).filter(item => item.classList.contains('hidden'));
                    const btn = lastVisible.querySelector('.gallery-first__last-btn');
                    if (btn) {
                        btn.style.display = hiddenItems.length > 0 ? 'block' : 'none';
                    }

                    updateBlurEffect(lastVisible, btn);
                }
            };

            updateShowMoreButton();

            galleryItems.forEach(item => {
                const btn = item.querySelector('.gallery-first__last-btn');
                if (btn) {
                    btn.addEventListener('click', () => {
                        const hiddenItems = Array.from(galleryItems).filter(item => item.classList.contains('hidden'));
                        hiddenItems.slice(0, 3).forEach(item => item.classList.remove('hidden'));

                        btn.style.display = 'none';

                        const imagesToUnblur = Array.from(item.querySelectorAll('.gallery-show-more'));
                        imagesToUnblur.forEach(image => image.style.filter = 'none');

                        updateShowMoreButton();
                    });
                }
            });
        }
    });
});













