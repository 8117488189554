document.addEventListener('DOMContentLoaded', function () {
    const header = document.querySelector('.header');
    const firstSection = document.querySelector('section');
    const content = document.querySelector('.margin');
    const modals = document.querySelectorAll('.modal__overlay');
    const containers = document.querySelectorAll('.container'); 
    const main = document.querySelector('main');
    let contentPaddingTop = 0;

    if (header) {
        function updateContentPadding() {
            const headerHeight = header.offsetHeight;

            if (headerHeight > 150) {
                contentPaddingTop = 240; 
            } else if (headerHeight > 105) {
                contentPaddingTop = 160; 
            } else {
                contentPaddingTop = 130;
            }
            if(content){
                content.style.paddingTop = `${contentPaddingTop}px`;
            }
            
            if (firstSection && !firstSection.classList.contains('section-banner')) {
                main.style.paddingTop = `${contentPaddingTop}px`;
            }

/*             modals.forEach(modal => {
                modal.style.paddingTop = `${contentPaddingTop}px`;
            }); */
        }

        updateContentPadding();
        window.addEventListener('resize', updateContentPadding);
    }

    const headerSixth = document.querySelector('.header-sixth');
    const MaxWidth = document.querySelector('.max-width');
    if (headerSixth) {
        containers.forEach(container => {
            container.style.paddingLeft = '85px';
            if(MaxWidth){
                MaxWidth.style.paddingLeft = '65px';
            }
            if (window.innerWidth <= 480) {
                container.style.paddingLeft = '15px';
                if(MaxWidth){
                    MaxWidth.style.paddingLeft = '0';
                }
            } 
        });
    }

    const parallaxElement = document.querySelector('.parallax');

    if (parallaxElement) {
        const parentContainer = document.querySelector('.parallax-container');

        if (parentContainer) {
            function applyParallaxEffect() {
                if (window.innerWidth <= 1120) {
                    parallaxElement.style.transform = 'translateY(0)';
                    return;
                }

                const containerRect = parentContainer.getBoundingClientRect();
                const parallaxHeight = parallaxElement.offsetHeight;
                const containerHeight = parentContainer.offsetHeight;

                const maxScrollAmount = Math.max(0, containerHeight - parallaxHeight - contentPaddingTop);

                if (containerRect.top <= window.innerHeight && containerRect.bottom >= 0) {
                    const scrollAmount = Math.min(maxScrollAmount, window.scrollY - parentContainer.offsetTop);

                    parallaxElement.style.transform = `translateY(${scrollAmount}px)`;
                }
            }

            applyParallaxEffect();

            window.addEventListener('scroll', applyParallaxEffect);
            window.addEventListener('resize', applyParallaxEffect);
        }
    }
});






