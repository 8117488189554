import Map from "../libs/Map";

export default (coordinates = "", zoom = 12) => ({
    async init() {
        await Map.loadAPI();
        const coords = coordinates.split(",", 2).map((value) => parseFloat(value));
/*         const coordsEls = document.querySelectorAll("[data-coords]");

        const coordsArray = Array.from(coordsEls).map((element) => {
            const dataCoords = element.getAttribute("data-coords"); 
            if (!dataCoords) return null; 
        
            const [lat, lng] = dataCoords.split(",").map((coord) => parseFloat(coord.trim()));
        
            return [lat, lng];
        }).filter((coords) => coords !== null); 
        
        console.log(coordsArray); */
        
        const map = new Map({
            container: this.$root,
            center: [coords[1], coords[0]],
            zoom,
        });
        map.addDefaultMarker([coords[1], coords[0]]);
/*         map.addMarkers(coordsArray, Map.createDefaultMarker()); */
    },
});