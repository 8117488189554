document.addEventListener('DOMContentLoaded', function () {
    const logo = document.querySelector('.logo');
    const desc = document.querySelector('.desc');

    function checkLogoWidth() {
        const logoWidth = logo.clientWidth;
        if (desc) {
            if (logoWidth >= 140) {
                if (desc) {
                    desc.classList.add('block-none');
                }
            } else {
                desc.classList.remove('block-none');
            }
        }
    }

    checkLogoWidth();

    logo.addEventListener('load', checkLogoWidth);
});
