document.addEventListener('DOMContentLoaded', function () {
    const menuToggle = document.querySelector('#menu-toggle');
    const mobileMenu = document.querySelector('#mobile-menu');
    const header = document.querySelector('.header');
    const fon = document.querySelector('.fon');
    const body = document.body;

    // Общая функция для сброса классов и стилей
    function resetMenuState() {
        if (menuToggle && menuToggle.classList.contains('active')) {
            menuToggle.classList.remove('active');
        }
        if (mobileMenu && mobileMenu.classList.contains('active')) {
            mobileMenu.classList.remove('active');
        }
        if (header && header.classList.contains('active')) {
            header.classList.remove('active');
        }
        if (fon && fon.classList.contains('active')) {
            fon.classList.remove('active');
        }
        body.style.overflow = ''; // Разблокировка прокрутки
    }

    // Обработчик для кнопки меню
    if (menuToggle) {
        menuToggle.addEventListener('click', function () {
            this.classList.toggle('active');

            if (mobileMenu) {
                mobileMenu.classList.toggle('active');
                body.style.overflow = mobileMenu.classList.contains('active') ? 'hidden' : '';
            }

            if (header) {
                header.classList.toggle('active');
            }

            if (fon) {
                fon.classList.toggle('active');
            }
        });
    }

    // Обработчик для якорных ссылок
    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    anchorLinks.forEach(link => {
        link.addEventListener('click', function (e) {
            e.preventDefault();

            // Прокрутка до блока с учетом высоты шапки и дополнительного отступа
            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const headerHeight = header ? header.offsetHeight : 0;
                const additionalOffset = window.innerWidth >= 1024 ? 150 : 55;
                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                const offsetPosition = targetPosition - headerHeight - additionalOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            }

            // Сброс меню и стилей
            resetMenuState();
        });
    });
});





document.addEventListener('DOMContentLoaded', function () {
    const DropDownBtn = document.querySelector('.btn-dropdown');
    if (DropDownBtn){
        DropDownBtn.addEventListener('click', function() {
            this.classList.toggle('active');
    
            const DropDownNav = document.querySelector('.dropdown');
    
            DropDownNav.classList.toggle('active');
        } )
    }
});

