import "./main.scss"
import "swiper/css"
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/pagination'
import 'swiper/css/controller'
import 'swiper/css/free-mode'

import "./js/index/mobile"
import "./js/index/logo"
import "./js/index/header"
import "./js/index/input"
import "./js/index/maskPhoneInput"
import "./js/index/video"
import "./js/index/metrika"

import "./js/parts/swiper"
import "./js/parts/show-more"
import "./js/parts/contact_form_7"
import "./js/parts/last-child"
import "./js/parts/dropdown-menu"
import "./js/parts/observer"
import "./js/parts/section"

import "htmx.org"

import "./js/alpine";
import "./js/modal";