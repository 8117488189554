document.addEventListener('DOMContentLoaded', () => {
    const observers = document.querySelectorAll('.observer');
    const line = document.querySelector('.section-works__main-line');
    const dynamicLine = document.querySelector('.section-works__dynamic-line');
    const container = document.querySelector('.section-works__list');

    const observerOptions = {
        root: null, // Отслеживать относительно viewport
        rootMargin: '0px 0px -10% 0px', // Отступы от границ
        threshold: 0.5 // Процент видимости элемента (50%)
    };

    let lastActiveElement = null;

    const observerCallback = (entries) => {
        entries.forEach(entry => {
            const { target, isIntersecting } = entry;

            if (isIntersecting) {
                target.classList.add('active');
                lastActiveElement = target;
            } else {
                target.classList.remove('active');

                if (lastActiveElement === target) {
                    lastActiveElement = null;
                }
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observers.forEach((section) => observer.observe(section));

    const updateLines = () => {
        const nums = document.querySelectorAll('.section-work__num');
        const firstNum = nums[0];
        let lastNum;
        if (window.innerWidth > 480) {
            lastNum = nums[nums.length - 1];
        } else {
            lastNum = nums[nums.length - 2];
        };
        if(container){
            const containerRect = container.getBoundingClientRect();

        const calculatePosition = (elem) => {
            const rect = elem.getBoundingClientRect();
            return rect.top + rect.height / 2 - containerRect.top;
        };

        const lineTop = calculatePosition(firstNum);
        const lineHeight = calculatePosition(lastNum) - lineTop;

        requestAnimationFrame(() => {
            line.style.top = `${lineTop}px`;
            line.style.height = `${lineHeight}px`;
        });
    };
    };

    updateLines();
    window.addEventListener('resize', updateLines);
});



/* import { startWatch } from 'Utils/lazy-load'

class Schema {
    constructor(element) {
        if (!element || this.isMobile())
            return

        this.schema        = element;
        this.line     = element.querySelector('.schema-line');
        this.lineFilling = this.line.querySelector('.schema-line__filling');

        this.init();
    }

    init() {
        const handler = (pixel, isVisible) => {
            const step = pixel.closest('.schema-step, .schema-last-step')
            isVisible ? this.animationShow(step) : this.animationHide(step)
        }
        const pixels = this.schema.querySelectorAll('.schema-step__watch-pixel')
        startWatch(pixels, handler, '500px 0px 0px', 0, false)
        this.fillingLine()
    }
    
    animationHide = elem => elem.classList.remove('show')
    animationShow = elem => elem.classList.add('show')

    fillingLine = () => {
        const setTranslate = translate => this.lineFilling.style.setProperty('transform', `translateY(${translate})`)

        const lineHandler = (line, isIntersecting, entry) => {
            if (!isIntersecting){
                setTranslate('0px')
                return
            }

            const rect = entry.boundingClientRect
            const top = rect.top - window.innerHeight * (6 / 7)

            if(entry.intersectionRatio > 0.97)
                setTranslate('100%')
            else if(top < 0 )
                setTranslate(`${top * -1}px`)
            else
                setTranslate('0px')
        }
        startWatch([this.line], lineHandler, '10% 0% -15% 0%', [...Array(50).keys()].map(x => x / 50), false )
    }

    isMobile = () => window.innerWidth < 992;
}

new Schema(document.querySelector('.schema')); */




