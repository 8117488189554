/* document.addEventListener('DOMContentLoaded', function () {
    const items = document.querySelectorAll('.advant-fifth__card');
    const columns = document.getAttribute('data-count'); 
    const lastItems = [];

    items.forEach((item, index) => {
        if ((index + 1) % columns === 0) {
            lastItems.push(item);
        }
    });

}); */
